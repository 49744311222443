import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div`
  ${tw`rounded-md bg-white shadow-card-shadow-1 p-6 min-h-369px h-full`}
`;

export const EmptyText = styled.div`
  ${tw`font-['Inter'] leading-5 text-dark-gray`}
`;

export const Title = styled.h3`
  ${tw`text-xl font-bold text-gray7 mb-6`}
`;

export const LinkContainer = styled.div`
  ${tw`flex flex-row gap-4`}
`;

export const SideContainer = styled.ul`
  ${tw`flex-1 flex flex-col gap-6`}
`;

export const TextLink = styled.a`
  ${tw`text-17-25 font-normal underline`}

  text-decoration-skip-ink: none;
`;
