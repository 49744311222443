import clsx from 'clsx';
import React, {
  FC, useState
} from 'react';
import {
  useSearchParams
} from 'react-router-dom';
import {
  useTranslation
} from 'react-i18next';

import {
  Avatar,
  Button,
  Spinner,
  successfulToast,
} from 'src/shared/components';
import {
  ArrowRightIcon, HeartIcon, MessageIcon
} from 'src/shared/icons';
import {
  followingId,
  questionId,
  showWarningFromServer,
  themeColors,
} from 'src/shared/utils';
import {
  useDeleteUserFollowingsMutation
} from 'src/redux/openapi';

import * as Style from './FollowingCard.style';

interface FollowingCardProps {
  user: {
    id: string;
    name: string;
    displayName: string | null;
    avatarUrl: string | null;
    commentsCount: number;
    forecastsCount: number;
  };
  refetch: () => void;
}

export const FollowingCard: FC<FollowingCardProps> = ({
  user: {
    id, avatarUrl, name, commentsCount, forecastsCount, displayName
  },
  refetch,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    t
  } = useTranslation();

  const handleViewActivity = () => {
    searchParams.set(
      followingId,
      id
    );

    searchParams.delete(questionId);

    setSearchParams(searchParams);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const isFollowingsComments = searchParams.get(followingId) === id;

  const [deleteFollowings, {
    isLoading: isDeleting
  }] = useDeleteUserFollowingsMutation();

  const handleRemoveFollowing = async () => {
    if (isDeleting) {
      return;
    }

    try {
      const resp = await deleteFollowings({
        followeeId: id,
      }).unwrap();

      successfulToast(resp.message);

      if (isFollowingsComments) {
        searchParams.delete(followingId);
        setSearchParams(searchParams);
      }

      refetch();
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  return (
    <Style.Container $isActive={isFollowingsComments}>
      <Style.UpperRow>
        <Avatar
          src={avatarUrl}
          size={40}
          userName={displayName || name}
        />

        <Style.NameContainer>
          <Style.Name>{displayName || name}</Style.Name>
        </Style.NameContainer>

        <Style.ButtonContainer
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Button
            variant="big-grey-bordered"
            className={clsx(
              'text-gray7 text-base font-semibold w-max !h-max !py-2 hover:text-red-text-error hover:!bg-baby-girl hover:min-w-99px',
            )}
            onClick={handleRemoveFollowing}
            disabled={isDeleting}
          >
            {isHovered ? t('community.unfollow') : t('community.following')}

            {isDeleting && (
              <Spinner
                color={themeColors.gray7}
                size={24}
                centered={false}
              />
            )}
          </Button>
        </Style.ButtonContainer>
      </Style.UpperRow>

      <Style.StatisticRow>
        <Style.Activity>{t('profile.Activity')}</Style.Activity>

        <Style.Statistic>
          <Style.MessageContainer>
            <MessageIcon className="text-dim-gray" />

            <Style.Span>{commentsCount}</Style.Span>

            {` / 24${t('date.h')}`}
          </Style.MessageContainer>

          <Style.MessageContainer>
            <HeartIcon className="text-dim-gray" />

            <Style.Span>{forecastsCount}</Style.Span>

            {` / 24${t('date.h')}`}
          </Style.MessageContainer>
        </Style.Statistic>
      </Style.StatisticRow>

      <Style.BottomRow>
        <Style.Button
          type="button"
          onClick={handleViewActivity}
        >
          {t('community.viewActivity')}

          <ArrowRightIcon className="text-button-blue" />
        </Style.Button>
      </Style.BottomRow>
    </Style.Container>
  );
};
