import React, {
  FC, useState
} from 'react';
import z from 'zod';
import {
  zodResolver
} from '@hookform/resolvers/zod';
import {
  useForm
} from 'react-hook-form';
import {
  useTranslation
} from 'react-i18next';

import {
  ActivityTile
} from 'src/entities/Questions';
import {
  ModalContainer,
  Avatar,
  Button,
  successfulToast,
  Spinner,
} from 'src/shared/components';
import {
  MessageIcon,
  PersonDeleteIcon,
  TrendingUpIcon,
} from 'src/shared/icons';
import {
  formatNumber, showWarningFromServer
} from 'src/shared/utils';
import {
  useDeleteGroupUsersMutation,
  useDeleteOrgUserMutation,
  GroupMember,
  OrganizationMember,
  GroupAdmin,
  OrganizationAdmin,
} from 'src/redux/openapi';
import {
  stringValidation
} from 'src/shared/utils/validation';

import * as Style from './DeleteUserFromGroupModal.styles';

const formSchema = z.object({
  comment: stringValidation,
});

enum ModalValues {
  COMMENT = 'comment',
}

interface CommentForm {
  comment: string;
}

export enum DeleteModalType {
  Organization = 'organization',
  Group = 'group',
}

interface DeleteUserFromGroupModalProps {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
  member: OrganizationMember | GroupMember | GroupAdmin | OrganizationAdmin;
  id?: string;
  type: DeleteModalType;
}

export const DeleteUserFromGroupModal: FC<DeleteUserFromGroupModalProps> = ({
  onClose,
  member,
  isOpen,
  refetch,
  type,
  id,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    t
  } = useTranslation();

  const {
    register, reset, handleSubmit
  } = useForm<CommentForm>({
    mode: 'all',
    defaultValues: {
      comment: '',
    },
    resolver: zodResolver(formSchema),
  });

  const {
    commentCount: commentsPerWeek,
    forecastCount: deltaPerWeek,
    name,
    displayName,
    id: userId,
  } = member;

  const handleClose = () => {
    reset();
    onClose();
  };

  const [deleteUserFromGroup] = useDeleteGroupUsersMutation();

  const [deleteUserFromOrganization] = useDeleteOrgUserMutation();

  const onSubmit = handleSubmit(async (val) => {
    if (!id) {
      return;
    }

    try {
      setIsLoading(true);

      const response = type === DeleteModalType.Group
        ? await deleteUserFromGroup({
          userId,
          id,
          reason: val.comment,
        }).unwrap()
        : await deleteUserFromOrganization({
          userId,
          id,
        }).unwrap();

      successfulToast(response.message);
      refetch();
      handleClose();
    } catch (error) {
      showWarningFromServer(error);
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <ModalContainer
      isOpen={isOpen}
      closeModalHandler={handleClose}
      modalTitle={`${t('modal.removeFrom')} ${t(`modal.${type}`)}`}
      modalWidth={677}
    >
      <Style.MemberRowContainer>
        <Style.MemberInfo>
          <Avatar
            size={48}
            userName={displayName || name}
          />

          <Style.MemberName>{displayName || name}</Style.MemberName>
        </Style.MemberInfo>

        <Style.ActivityRow>
          <ActivityTile
            icon={<MessageIcon className="w-4 h-4 text-dim-gray" />}
            amount={formatNumber(commentsPerWeek || 0)}
            timeRange="upToday"
            style={{
              fontSize: '13px',
            }}
          />

          <ActivityTile
            icon={<TrendingUpIcon className="w-4 h-4 text-dim-gray" />}
            amount={`${deltaPerWeek}`}
            timeRange="upToday"
            style={{
              fontSize: '13px',
            }}
          />
        </Style.ActivityRow>
      </Style.MemberRowContainer>

      <div className="mt-6">
        <Style.ModalLabel htmlFor={`${ModalValues.COMMENT}`}>
          {t('modal.describeReasonOptional')}
        </Style.ModalLabel>

        <Style.ModalTextarea
          {...register(ModalValues.COMMENT)}
          placeholder={`${t('modal.whyRemoveMemberFrom')} ${t(
            `modal.${type}`,
          )}?`}
          id={`${ModalValues.COMMENT}`}
        />
      </div>

      <Style.ButtonContainer>
        <Button
          variant="big-black"
          onClick={handleClose}
          className="w-max"
        >
          <Style.CancelText>{t('buttons.cancel')}</Style.CancelText>
        </Button>

        <Button
          variant="big-red"
          onClick={onSubmit}
          className="w-max"
          disabled={isLoading}
        >
          <Style.CancelText>{t('modal.removeTheUser')}</Style.CancelText>

          {isLoading ? (
            <Spinner
              color="white"
              size={24}
              centered={false}
            />
          ) : (
            <PersonDeleteIcon className="w-6 h-6 text-white" />
          )}
        </Button>
      </Style.ButtonContainer>
    </ModalContainer>
  );
};
