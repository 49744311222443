import React from 'react';

import {
  DiscussionSection, PageLayout
} from 'src/widgets';
import {
  CommunityFollowerSection
} from 'src/widgets/Sections/CommunityCommentsSection';
import {
  communitySelectors, CommunityTabId
} from 'src/redux/community';
import {
  useTypedSelector
} from 'src/redux';

import * as Style from './Community.styles';
import {
  ActiveQuestionCommunity
} from './ActiveQuestionCommunity';

export const Community = () => {
  const activeTab = useTypedSelector(communitySelectors.selectLastActiveTab);

  return (
    <Style.PageContainer>
      <PageLayout>
        <Style.MainContainer>
          <DiscussionSection />

          {(activeTab === CommunityTabId.Active
            || activeTab === CommunityTabId.Scored) && (
            <ActiveQuestionCommunity tab={activeTab} />
          )}

          {activeTab === CommunityTabId.Followings && (
            <CommunityFollowerSection />
          )}
        </Style.MainContainer>
      </PageLayout>
    </Style.PageContainer>
  );
};
