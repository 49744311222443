import React, {
  FC, useState, useRef, useMemo
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import {
  useSearchParams
} from 'react-router-dom';

import {
  commentSearch,
  followingId,
  getSearchRegex,
  themeColors,
} from 'src/shared/utils';
import {
  SearchButton, Spinner
} from 'src/shared/components';
import {
  CommentsFilterContext, CommunitySearch
} from 'src/widgets';
import {
  useGetFavUserComments
} from 'src/shared/hooks';
import {
  useTypedSelector
} from 'src/redux';
import {
  communitySelectors
} from 'src/redux/community';

import {
  Title
} from '../Sections.styles';

import * as Style from './CommunityCommentsSection.styles';
import {
  CommentsList
} from './CommentsList';

export const CommunityFollowerSection: FC = () => {
  const {
    t
  } = useTranslation();

  const [isSearchActive, setIsSearchActive] = useState(false);
  const [searchParams] = useSearchParams();

  const commentsRef = useRef<HTMLDivElement | null>(null);

  const toggleSearch = () => setIsSearchActive((prev) => !prev);

  const searchQuery = searchParams.get(commentSearch) || '';
  const followerId = searchParams.get(followingId) || '';

  const {
    commentsList,
    isLoading,
    loadNextPage,
    refetch,
    isLoadingMoreComments,
    total,
  } = useGetFavUserComments();

  const matches = useMemo(
    () => {
      if (!searchQuery) {
        return 0;
      }

      return commentsList.reduce(
        (count, comment) => {
          const regex = getSearchRegex(searchQuery);

          const matchesCount = comment.text?.match(regex)?.length || 0;

          return count + matchesCount;
        },
        0
      );
    },
    [searchParams, commentsList]
  );

  const {
    loading, emptyMessage
  } = useTypedSelector(
    communitySelectors.selectFollowingsTab,
  );

  if (loading || isLoading) {
    return <Spinner />;
  }

  if (!followerId && !commentsList.length) {
    return (
      <Style.EmptyChatContainer>
        <Style.EmptyMessageTitle>{emptyMessage.title}</Style.EmptyMessageTitle>

        {emptyMessage.subtitle}
      </Style.EmptyChatContainer>
    );
  }

  return (
    <Style.SectionWide
      id="CommunityCommentsSection"
      $withSearch={isSearchActive}
      className="relative"
    >
      <Style.SectionHeader>
        <div>
          <Title>{t('profile.Activity')}</Title>
        </div>

        <Style.ButtonsContainer>
          <SearchButton
            onClick={toggleSearch}
            borderColor={
              isSearchActive ? themeColors['button-blue'] : themeColors.gray90
            }
            iconColor={
              isSearchActive
                ? themeColors['button-blue']
                : themeColors['dim-gray']
            }
          />

          <CommentsFilterContext />
        </Style.ButtonsContainer>
      </Style.SectionHeader>

      {isSearchActive && (
        <CommunitySearch
          matches={matches}
          onClose={toggleSearch}
          commentsRef={commentsRef}
          comments={commentsList}
        />
      )}

      {isLoading ? (
        <Spinner />
      ) : (
        <CommentsList
          commentsRef={commentsRef}
          comments={followerId ? commentsList : []}
          isLoading={isLoading}
          loadNextPage={loadNextPage}
          isLoadingMoreComments={isLoadingMoreComments}
          refetch={refetch}
          total={total}
          hasQuestionComments={false}
        />
      )}
    </Style.SectionWide>
  );
};
