import {
  CommunityReducer
} from './communitySlice';
import {
  CommunityTabId
} from './types';

const selectActiveQuestionsTab = (state: CommunityReducer) => {
  return state.community.active;
};

const selectScoredQuestionsTab = (state: CommunityReducer) => {
  return {
    ...state.community.scored,
    filters: state.community.scoredTabFilters || {
      selectedSets: [],
      timePeriod: null,
    },
  };
};

const selectFollowingsTab = (state: CommunityReducer) => {
  return state.community.followings;
};

const selectAllTabs = (state: CommunityReducer) => {
  return state.community;
};

const selectLastActiveTab = (state: CommunityReducer) => {
  return state.community.lastActiveTab || CommunityTabId.Active;
};

export const communitySelectors = {
  selectActiveQuestionsTab,
  selectScoredQuestionsTab,
  selectFollowingsTab,
  selectAllTabs,
  selectLastActiveTab,
};
