import React, {
  FC, useEffect, useRef, useState
} from 'react';
import {
  useLocation, useNavigate
} from 'react-router-dom';
import {
  createPortal
} from 'react-dom';
import {
  Trans, useTranslation
} from 'react-i18next';

import {
  useDeleteGroupUsersCurrentMutation,
  useGetGroupUsersQuery,
  usePatchGroupUsersSuperadminMutation,
  useGetOrganizationUsersQuery,
  usePatchOrganizationUserSuperadminMutation,
  useDeleteOrganizationUserCurrentMutation,
  Group,
  Organization,
  UserGroupRole,
  useGetOrganizationUsersAdminsQuery,
  GroupMember,
  OrganizationMember,
  OrganizationAdmin,
  GroupAdmin,
} from 'src/redux/openapi';
import {
  ROUTE, isOrganization, showWarningFromServer
} from 'src/shared/utils';
import {
  useTypedSelector
} from 'src/redux';
import {
  userSelectors
} from 'src/redux/user';
import {
  ArrowDownIcon, SearchIcon
} from 'src/shared/icons';

import {
  Button
} from '../Button';
import {
  ModalContainer
} from '../ModalContainer';
import {
  successfulToast
} from '../Toasts';
import {
  Spinner
} from '../Spinner';
import {
  Avatar
} from '../Avatar';
import {
  InputType
} from '../Input';

import * as Style from './ModalLeave.styles';
import {
  PortalMember
} from './PortalMember';

interface ModalLeaveProps {
  closeModal: () => void;
  isOpen: boolean;
  group?: Group;
  organization?: Organization;
  onLeave?: () => void;
}

type Member = GroupMember | OrganizationMember;

type Admin = OrganizationAdmin | GroupAdmin;

export const ModalLeave: FC<ModalLeaveProps> = ({
  closeModal,
  isOpen,
  group,
  organization,
  onLeave,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    t
  } = useTranslation();

  const isGroupPage = location.pathname.startsWith('/groups');

  const [showMembers, setShowMembers] = useState(false);
  const canLeaveGroup = group && group.groupRole !== UserGroupRole.SUPERADMIN;
  const [searchMember, setSearchMember] = useState('');

  const canLeaveOrg = organization && organization.role !== UserGroupRole.SUPERADMIN;

  const {
    data: {
      data: groupMembersData
    } = {}
  } = useGetGroupUsersQuery(
    {
      id: group?.id || '',
      query: searchMember,
    },
    {
      skip: !group,
    },
  );

  const {
    data: {
      data: organizationMembersData
    } = {}
  } = useGetOrganizationUsersQuery(
    {
      id: organization?.id || '',
      query: searchMember,
    },
    {
      skip: !organization,
    },
  );

  const {
    data: {
      data: organizationAdminsData
    } = {}
  } = useGetOrganizationUsersAdminsQuery(
    {
      id: organization?.id || '',
      query: searchMember,
    },
    {
      skip: !organization,
    },
  );

  const members: Member[] = groupMembersData?.members || organizationMembersData?.members || [];

  const admins: Admin[] = groupMembersData?.admins || organizationAdminsData?.admins || [];

  const notAdminUsers = members?.filter(
    (member) => !admins.some((admin) => admin.id === member.id),
  ) || [];

  const users = [...notAdminUsers, ...admins];

  const canLeave = (canLeaveGroup && group.id) || (canLeaveOrg && organization);

  const {
    avatarUrl: avatarSrc,
    name,
    id,
    displayName,
  } = useTypedSelector(userSelectors.user);

  const userName = displayName || name;

  const [selectedUserId, setSelectedUserId] = useState(id);

  const selectedUser = users.find((user) => user.id === selectedUserId);

  useEffect(
    () => {
      setSelectedUserId(id);
    },
    [group, organization]
  );

  const isCurrentGroupPage = !!group && location.pathname.includes(group.id);

  const isCurrentOrganisationPage = !!organization && location.pathname.includes(organization.id);

  const [
    leaveFromGroup,
    {
      isLoading: isLeaveLoading, isSuccess: isLeaveSuccess
    },
  ] = useDeleteGroupUsersCurrentMutation();

  const [
    leaveFromOrganisation,
    {
      isLoading: isLeaveLoadingOrg, isSuccess: isLeaveSuccessOrg
    },
  ] = useDeleteOrganizationUserCurrentMutation();

  const [assignNewGroupSuperAdmin, {
    isLoading: isLoadingAssigning
  }] = usePatchGroupUsersSuperadminMutation();

  const [assignNewOrgSuperAdmin] = usePatchOrganizationUserSuperadminMutation();

  const handleLeave = async () => {
    if (!isLeaveLoading && group) {
      try {
        const response = await leaveFromGroup({
          id: group.id,
        }).unwrap();

        successfulToast(response.message);
      } catch (error) {
        showWarningFromServer(error);
      }
    }

    if (!isLeaveLoadingOrg && organization) {
      try {
        const response = await leaveFromOrganisation({
          id: organization.id,
        }).unwrap();

        successfulToast(response.message);
      } catch (error) {
        showWarningFromServer(error);
      }
    }
  };

  const handleAssignSuperAdmin = async () => {
    try {
      let response;

      if (group) {
        response = await assignNewGroupSuperAdmin({
          id: group.id,
          assignSuperadminBodySchema: {
            userId: selectedUserId,
          },
        }).unwrap();
      }

      if (organization) {
        await assignNewOrgSuperAdmin({
          id: organization.id,
          assignSuperadminBodySchema: {
            userId: selectedUserId,
          },
        }).unwrap();
      }

      if (response) {
        handleLeave();
        successfulToast(response.message);
      }
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  useEffect(
    () => {
      if (isLeaveSuccess && group) {
        if (onLeave) {
          onLeave();
        }

        if (isCurrentGroupPage) {
          navigate(`/${ROUTE.GROUPS}`);
        }

        closeModal();
      }

      if (isLeaveSuccessOrg && organization) {
        if (onLeave) {
          onLeave();
        }

        if (isCurrentOrganisationPage) {
          navigate(`/${ROUTE.ORGANISATION}`);
        }

        closeModal();
      }
    },
    [isLeaveSuccess, isLeaveSuccessOrg]
  );

  const portalRef = useRef<HTMLDivElement>(null);

  const [listMaxHeight, setListMaxHeight] = useState(372);

  useEffect(
    () => {
      if (portalRef.current) {
        const res = portalRef.current.getClientRects();

        const maxHeight = window.innerHeight - res[0].top - 8;

        if (maxHeight < listMaxHeight) {
          setListMaxHeight(maxHeight);
        }
      }
    },
    [portalRef, showMembers]
  );

  const handleNewMemberSelect = (newId: string) => {
    setSelectedUserId(newId);
    setShowMembers(false);
  };

  const getGroupName = () => {
    if (group) {
      return group.name;
    }

    if (selectedUser && isOrganization(selectedUser)) {
      return selectedUser.groupName;
    }

    return '';
  };

  const leaveModalWidth = isGroupPage ? 497 : 587;

  return (
    <ModalContainer
      modalWidth={canLeave ? leaveModalWidth : 458}
      isDarkBackground
      isHeader={!canLeave}
      closeModalHandler={() => {
        setShowMembers(false);
        closeModal();
      }}
      isOpen={isOpen}
      modalTitle={!canLeave ? t('modal.chooseNewAdminAndLeave') : undefined}
    >
      {canLeave && (
        <Style.ModalContainer>
          <Style.ModalTitle>
            <Trans
              i18nKey="modal.sureWantToLeave"
              values={{
                type: isGroupPage ? t('modal.group') : t('modal.organization'),
              }}
            />
          </Style.ModalTitle>

          <Style.ModalText>
            <Trans
              i18nKey="modal.toRejoinTheGroup"
              values={{
                groupName: getGroupName(),
              }}
            />
          </Style.ModalText>

          <Style.ButtonContainer>
            <Button
              variant="big-black"
              onClick={closeModal}
            >
              <Style.CancelText>{t('buttons.cancel')}</Style.CancelText>
            </Button>

            <Button
              variant="big-red"
              onClick={handleLeave}
              disabled={isLeaveLoading}
            >
              {isLeaveLoading ? (
                <Spinner
                  color="white"
                  size={24}
                  centered={false}
                />
              ) : (
                <Style.CancelText>{t('buttons.leave')}</Style.CancelText>
              )}
            </Button>
          </Style.ButtonContainer>
        </Style.ModalContainer>
      )}

      {!canLeave && (
        <Style.ModalContainer $isAdminView>
          <Style.ModalTextLeave>
            <Trans
              i18nKey="modal.beforeLeavingTheGroup"
              values={{
                type: isGroupPage ? t('modal.group') : t('modal.organization'),
              }}
            />
          </Style.ModalTextLeave>

          <Style.MemberRowContainer>
            <Style.MemberInfo onClick={() => setShowMembers((prev) => !prev)}>
              <Avatar
                size={48}
                src={selectedUser?.id !== id ? null : avatarSrc}
                userName={selectedUser ? selectedUser.name : userName}
              />

              {group && (
                <Style.MemberName $isSelected>
                  {selectedUser ? selectedUser.name : userName}
                </Style.MemberName>
              )}

              {organization && (
                <div className="flex-1 text-left">
                  {selectedUser && isOrganization(selectedUser) && (
                    <div className="text-xs font-medium leading-5 text-dim-gray mb-0.5">
                      {selectedUser.groupName}
                    </div>
                  )}

                  <Style.MemberName $isSelected>
                    {selectedUser ? selectedUser.name : userName}
                  </Style.MemberName>
                </div>
              )}

              <ArrowDownIcon className="self-center" />
            </Style.MemberInfo>
          </Style.MemberRowContainer>

          <Style.ButtonContainer $isAdminView>
            <Button
              variant="big-grey-bordered"
              onClick={closeModal}
            >
              <Style.CancelText>{t('buttons.cancel')}</Style.CancelText>
            </Button>

            <Button
              variant="big-blue"
              onClick={handleAssignSuperAdmin}
              disabled={isLeaveLoading || isLoadingAssigning}
            >
              {isLeaveLoading || isLoadingAssigning ? (
                <Spinner
                  color="white"
                  size={24}
                  centered={false}
                />
              ) : (
                <Style.CancelText>
                  {`${t('buttons.leave')} ${
                    isGroupPage ? t('common.group') : ''
                  }`}
                </Style.CancelText>
              )}
            </Button>
          </Style.ButtonContainer>

          {showMembers
            && createPortal(
              <Style.PortalContainer
                ref={portalRef}
                style={{
                  maxHeight: `${listMaxHeight}px`,
                }}
              >
                <Style.PortalSearch>
                  <Style.PortalSearchInput
                    type={InputType.TEXT}
                    value={searchMember}
                    onChange={(e) => setSearchMember(e.target.value)}
                    placeholder={`${t('modal.searchForMember')}...`}
                  />

                  <SearchIcon className="text-dim-gray w-6 h-6" />
                </Style.PortalSearch>

                <Style.PortalMemberList
                  style={{
                    maxHeight: `${listMaxHeight - 94}px`,
                  }}
                >
                  {admins.map((user) => (
                    <PortalMember
                      key={user.id}
                      user={user}
                      selectedUserId={selectedUserId}
                      handleClick={handleNewMemberSelect}
                      isAdmin
                      isCurrentUser={user.id === id}
                    />
                  ))}

                  {notAdminUsers.map((user) => (
                    <PortalMember
                      key={user.id}
                      user={user}
                      selectedUserId={selectedUserId}
                      handleClick={handleNewMemberSelect}
                      isCurrentUser={user.id === id}
                    />
                  ))}

                  {!members.length && !admins.length && (
                    <Style.EmptyMessage>
                      {t('empty.noMembersFound')}
                    </Style.EmptyMessage>
                  )}
                </Style.PortalMemberList>
              </Style.PortalContainer>,
              document.body,
            )}
        </Style.ModalContainer>
      )}
    </ModalContainer>
  );
};
