import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  formatDateRange
} from 'src/shared/utils';
import {
  Input
} from 'src/shared/components';

import {
  QuestionWithOnboardingText
} from '../../types';

import * as Style from './OnboardingTextItem.styles';

interface OnboardingTextItemProps {
  question: QuestionWithOnboardingText;
  index: number;
  onTextChange: (id: string, value: string) => void;
}

export const OnboardingTextItem: FC<OnboardingTextItemProps> = ({
  question,
  index,
  onTextChange,
}) => {
  const {
    t,
    i18n: {
      language
    },
  } = useTranslation();

  const {
    title, startDate, endDate, id, onboardingText, status
  } = question;

  const dates = formatDateRange({
    start: startDate,
    end: endDate,
    language,
  });

  const isUnavailable = status === 'UNAVAILABLE';

  return (
    <Style.MainContainer>
      <Style.Index>{`${index + 1}.`}</Style.Index>

      <Style.ContentWrapper>
        <Style.HeadWrapper>
          <Style.Title>{title}</Style.Title>

          <Style.SubtitleWrapper>
            <Style.Subtitle>{dates}</Style.Subtitle>

            {isUnavailable && (
              <Style.UpcomingTile>{t('question.upcoming')}</Style.UpcomingTile>
            )}
          </Style.SubtitleWrapper>
        </Style.HeadWrapper>

        <Input
          placeholder={`${t('question.onboardingTextForQuestions')} ${t(
            'common.optional',
          )}`}
          value={onboardingText ?? ''}
          onChange={(val) => onTextChange(
            id,
            val
          )}
          className="!h-10"
        />
      </Style.ContentWrapper>
    </Style.MainContainer>
  );
};
