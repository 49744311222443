import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import {
  useNavigate
} from 'react-router-dom';

import {
  Button, Spinner
} from 'src/shared/components';
import {
  ArrowLeftIcon
} from 'src/shared/icons';
import {
  ROUTE
} from 'src/shared/utils';
import {
  useGetQuestionAdjacentQuery
} from 'src/redux/openapi';

import * as Style from './QuestionDetailsNavigation.styles';

interface QuestionDetailsNavigationProps {
  isDeactivated: boolean;
  handleSubmitClick: () => Promise<void>;
  disableSubmitButton: boolean;
  isSubmitting: boolean;
  questionId: string;
}

export const QuestionDetailsNavigation: FC<QuestionDetailsNavigationProps> = ({
  isDeactivated,
  handleSubmitClick,
  disableSubmitButton,
  isSubmitting,
  questionId,
}) => {
  const {
    t
  } = useTranslation();

  const navigate = useNavigate();

  const {
    data, isLoading, isFetching
  } = useGetQuestionAdjacentQuery({
    id: questionId,
  });

  const {
    nextId = null, previousId = null
  } = data?.data || {};

  const onNextClick = () => {
    if (nextId) {
      navigate(`/${ROUTE.QUESTION}/${nextId}`);
    }
  };

  const onPrevClick = () => {
    if (previousId) {
      navigate(`/${ROUTE.QUESTION}/${previousId}`);
    }
  };

  const onSubmitAndNext = async () => {
    await handleSubmitClick();

    onNextClick();
  };

  const disableNavigation = isLoading || isFetching;

  return (
    <Style.MainContainer>
      {(nextId || previousId) && (
        <Style.NavigateButtonsContainer>
          <Button
            variant="big-grey-bordered"
            className="w-max px-4 md:px-6 py-2.5 md:py-3 text-xl text-dim-gray font-bold h-max md:h-52px disabled:opacity-35"
            disabled={!previousId || disableNavigation}
            onClick={onPrevClick}
          >
            <ArrowLeftIcon />
          </Button>

          <Button
            variant="big-grey-bordered"
            className="w-max px-4 md:px-6 py-2.5 md:py-3 text-xl text-dim-gray font-bold h-max md:h-52px disabled:opacity-35"
            disabled={!nextId || disableNavigation}
            onClick={onNextClick}
          >
            <ArrowLeftIcon className="rotate-180" />
          </Button>
        </Style.NavigateButtonsContainer>
      )}

      {!isDeactivated && (
        <Style.SubmitNextButtonsContainer>
          <Button
            variant="big-black"
            className="w-max text-16-28 md:text-xl text-white font-bold h-max md:h-52px"
            onClick={handleSubmitClick}
            disabled={disableSubmitButton}
          >
            {t('buttons.submit')}

            {isSubmitting && (
              <Spinner
                color="white"
                size={24}
                centered={false}
              />
            )}
          </Button>

          {nextId && (
            <Button
              variant="big-blue"
              className="w-max text-16-28 md:text-xl text-white font-bold h-max md:h-52px"
              onClick={onSubmitAndNext}
              disabled={disableSubmitButton || disableNavigation}
            >
              {t('buttons.submitAndNext')}

              {isSubmitting && (
                <Spinner
                  color="white"
                  size={24}
                  centered={false}
                />
              )}
            </Button>
          )}
        </Style.SubmitNextButtonsContainer>
      )}
    </Style.MainContainer>
  );
};
