import {
  TimePeriodSelectOption
} from 'src/shared/components';

export enum CommunityTabId {
  Active = 'active',
  Followings = 'followings',
  Scored = 'scored',
}

export enum ActiveTabSortBy {
  Latest = 'createdAt',
  MostCommented = 'commentCount',
  MostParticipants = 'participantsCount',
}

export enum ScoredTabSortBy {
  Newest = 'Newest',
  Oldest = 'Oldest',
  Higher = 'Higher',
  Lower = 'Lower',
}

export enum FollowingTabSortBy {
  MostEngaged = 'mostEngaged',
}

export type SortByInCommunity =
  | ActiveTabSortBy
  | ScoredTabSortBy
  | FollowingTabSortBy;

export type EmptyMessage = { title: string; subtitle?: string };

export type TabState<T> = {
  loading: boolean;
  emptyMessage: EmptyMessage;
  sortBy: T;
};

export type ScoredSelectSetsOption = {
  label: string;
  value: string;
};

export type ScoredTabFilters = {
  selectedSets: ScoredSelectSetsOption[];
  timePeriod: TimePeriodSelectOption | null;
};
