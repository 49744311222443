import React, {
  FC
} from 'react';

import {
  Spinner
} from 'src/shared/components';

import {
  OnboardingTextItem
} from './ui';
import * as Style from './SetOnboardingTextList.styles';
import {
  QuestionWithOnboardingText
} from './types';

interface SetOnboardingTextListProps {
  questions: QuestionWithOnboardingText[];
  onTextChange: (id: string, value: string) => void;
  isLoading?: boolean;
}

export const SetOnboardingTextList: FC<SetOnboardingTextListProps> = ({
  questions,
  onTextChange,
  isLoading,
}) => {
  return (
    <Style.ListContainer>
      {isLoading ? (
        <Spinner
          size={40}
          withLoadingText
        />
      ) : (
        <>
          {questions.map((question, index) => {
            return (
              <OnboardingTextItem
                question={question}
                index={index}
                onTextChange={onTextChange}
                key={question.id}
              />
            );
          })}
        </>
      )}
    </Style.ListContainer>
  );
};
