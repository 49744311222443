import React, {
  FC, useRef, useEffect, useMemo
} from 'react';
import {
  useLocation, useNavigate, useSearchParams
} from 'react-router-dom';

import {
  SortPopoverMenu
} from 'src/shared/components';
import {
  followingId, groupId, questionId
} from 'src/shared/utils';
import {
  useTypedDispatch, useTypedSelector
} from 'src/redux';
import {
  communitySelectors,
  CommunityTabId,
  setCommunityLastActiveTab,
  setCommunitySortBy,
  SortByInCommunity,
} from 'src/redux/community';

import {
  Title
} from '../Sections.styles';

import * as Style from './DiscussionSection.styles';
import {
  getActiveSortOptionsMap,
  getFollowingSortOptionsMap,
  getScoredSortOptionsMap,
  getTabsMap,
} from './utils';

export const DiscussionSection: FC = () => {
  const commentToScrollRef = useRef<HTMLDivElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const activeQuestionId = searchParams.get(questionId) || null;
  const isFollowingId = !!searchParams.get(followingId);
  const activeFilterId = searchParams.get(groupId);

  const tabsData = useTypedSelector(communitySelectors.selectAllTabs);
  const activeTab = tabsData.lastActiveTab || CommunityTabId.Active;
  const tabs = getTabsMap();

  const activeTabContent = tabs.find((tab) => tab.id === activeTab)?.content || null;

  const activeSortBy = tabsData[activeTab].sortBy;

  const location = useLocation();
  const navigate = useNavigate();

  const {
    communityTab: locationCommunityTab
  } = location.state || {};

  const dispatch = useTypedDispatch();

  const onSortClick = (sortType: SortByInCommunity) => {
    dispatch(
      setCommunitySortBy({
        tabName: activeTab,
        sortBy: sortType,
      }),
    );
  };

  useEffect(
    () => {
      if (commentToScrollRef.current && activeQuestionId) {
        commentToScrollRef.current.scrollIntoView();
      }
    },
    [activeFilterId]
  );

  useEffect(
    () => {
      if (locationCommunityTab || isFollowingId) {
        const tab = locationCommunityTab || (isFollowingId && CommunityTabId.Followings);

        dispatch(
          setCommunityLastActiveTab({
            tab,
          }),
        );

        navigate(
          location,
          {
            replace: true,
            state: {
              ...location.state,
              communityTab: null,
            },
          }
        );
      }
    },
    [locationCommunityTab, isFollowingId]
  );

  const sortByItems = useMemo(
    () => {
      if (activeTab === CommunityTabId.Followings) {
        return getFollowingSortOptionsMap();
      }

      if (activeTab === CommunityTabId.Scored) {
        return getScoredSortOptionsMap();
      }

      return getActiveSortOptionsMap();
    },
    [activeTab]
  );

  const onTabChange = (tabId: CommunityTabId) => {
    dispatch(
      setCommunityLastActiveTab({
        tab: tabId,
      }),
    );

    searchParams.delete(questionId);
    searchParams.delete(followingId);

    setSearchParams(searchParams);
  };

  return (
    <Style.SectionContainer>
      <Style.SectionHeader>
        <Style.TitleWrapper>
          <SortPopoverMenu
            options={sortByItems}
            onSelect={(value) => onSortClick(value)}
            currentValue={activeSortBy}
          />

          <Style.TabsContainer className="scroll-hidden">
            {tabs.map((tab) => (
              <button
                onClick={() => onTabChange(tab.id)}
                key={tab.id}
                className="shrink-0"
                type="button"
              >
                <Title $isActive={activeTab === tab.id}>{tab.label}</Title>
              </button>
            ))}
          </Style.TabsContainer>
        </Style.TitleWrapper>
      </Style.SectionHeader>

      {activeTabContent}
    </Style.SectionContainer>
  );
};
