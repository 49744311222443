import React, {
  FC, useMemo, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  useGetQuestionDelegationsUsersQuery
} from 'src/redux/openapi';
import {
  useCustomInfiniteScroll
} from 'src/shared/hooks';
import {
  Avatar, Checkbox, Spinner
} from 'src/shared/components';

import * as Style from './GroupUsersList.styles';

interface GroupUsersListProps {
  groupId: string;
  questionId: string;
  onDelegateChange: (id: string | null) => void;
  delegateId: string | null;
}

export const GroupUsersList: FC<GroupUsersListProps> = ({
  groupId,
  questionId,
  onDelegateChange,
  delegateId,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const {
    t
  } = useTranslation();

  const {
    data: usersData,
    isFetching,
    isLoading,
    isError,
  } = useGetQuestionDelegationsUsersQuery({
    id: questionId,
    page: currentPage,
    limit: 10,
    groupId,
  });

  const {
    total: totalUsers, users
  } = useMemo(
    () => usersData?.data || {
      total: 0,
      users: [],
    },
    [usersData],
  );

  const {
    allItems: allMembers,
    sentryRef,
    displayLoader,
  } = useCustomInfiniteScroll({
    total: totalUsers,
    currentItems: users,
    isFetching,
    isLoading,
    isError,
    currentPage,
    setCurrentPage,
  });

  return (
    <Style.AssignMembersGroupContainer>
      <Style.AssignMembersGroupTitle>
        {t('modal.selectDelegate')}
      </Style.AssignMembersGroupTitle>

      <Style.AssignMembersGroupText>
        {`${t('group.members')}: `}

        <Style.AssignMembersGroupSpan>
          {totalUsers}
        </Style.AssignMembersGroupSpan>
      </Style.AssignMembersGroupText>

      {isLoading ? (
        <Spinner size={24} />
      ) : (
        <Style.AssignMembersContainer>
          {allMembers.map(({
            id, name, displayName
          }, index) => {
            const isChecked = delegateId === id;
            const userName = displayName || name;

            return (
              <Style.AssignMemberContainer key={id}>
                <Checkbox
                  onChange={() => onDelegateChange(isChecked ? null : id)}
                  type="checkMark"
                  isChecked={isChecked}
                />

                <Style.AssignMemberRow>
                  <Style.AssignMemberText>
                    {`${index + 1}.`}
                  </Style.AssignMemberText>

                  <Avatar
                    size={32}
                    userName={userName}
                  />

                  <Style.AssignMemberText>{userName}</Style.AssignMemberText>
                </Style.AssignMemberRow>
              </Style.AssignMemberContainer>
            );
          })}

          {displayLoader && (
            <div ref={sentryRef}>
              <Spinner centered={false} />
            </div>
          )}
        </Style.AssignMembersContainer>
      )}
    </Style.AssignMembersGroupContainer>
  );
};
