import {
  t
} from 'i18next';

import {
  CheckmarkThinIcon, MinusIcon
} from 'src/shared/icons';
import {
  ROUTE
} from 'src/shared/utils';

export enum Steps {
  ChooseQuestions = 'ChooseQuestions',
  ChooseGroups = 'ChooseGroups',
  SetOnboardingText = 'SetOnboardingText',
}

export const getStepsMap = (isAdding: boolean, isGroup?: boolean) => {
  const onboardingStep = {
    id: Steps.SetOnboardingText,
    label: `${t('question.setOnboardingText')}`,
  };

  const chooseQuestionsStep = {
    id: Steps.ChooseQuestions,
    label: `${t('question.chooseQuestions')}`,
  };

  const chooseGroupsStep = {
    id: Steps.ChooseGroups,
    label: `${t('group.chooseGroups')}`,
  };

  if (isGroup) {
    return [chooseQuestionsStep, onboardingStep];
  }

  if (!isAdding) {
    return [chooseQuestionsStep, chooseGroupsStep];
  }

  return [chooseQuestionsStep, chooseGroupsStep];
};

export const getNextStepButtonConfig = ({
  isLastStep,
  isAdding,
}: {
  isLastStep: boolean;
  isAdding: boolean;
}) => {
  if (!isLastStep) {
    return {
      title: t('buttons.next'),
    };
  }

  return isAdding
    ? {
      title: t('buttons.add'),
      icon: CheckmarkThinIcon,
    }
    : {
      title: t('buttons.remove'),
      icon: MinusIcon,
    };
};

export const getBreadcrumbsRoutes = (organizationName?: string) => [
  {
    path: `/${ROUTE.ORGANISATION}/:id`,
    breadcrumb: organizationName || 'Loading...',
  },
  {
    path: `/${ROUTE.ORGANISATION}/:id/${ROUTE.ADD_IN_BULK}`,
    breadcrumb: t('question.addQuestions'),
  },
  {
    path: `/${ROUTE.ORGANISATION}/:id/${ROUTE.REMOVE_IN_BULK}`,
    breadcrumb: t('question.removeQuestions'),
  },
];
export const getBreadcrumbsGroupRoutes = (groupName?: string) => [
  {
    path: `/${ROUTE.GROUPS}/:id`,
    breadcrumb: groupName || 'Loading...',
  },
  {
    path: `/${ROUTE.GROUPS}/:id/${ROUTE.SET_ONBOARDING_TEXT}`,
    breadcrumb: t('question.chooseQuestions'),
  },
];
